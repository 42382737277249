import { completeRegistration } from '@util/analytics';
import { db, functions, httpsCallable } from '@util/firebase';
import { AddressDocument } from '@util/types/firestore/address';
import { UserDocument, userDocumentSchema } from '@util/types/firestore/users';
import { slugify } from '@util/urlHelpers';
import AuthProvider from 'context/AuthContext';
import {
  arrayUnion,
  arrayRemove,
  collection,
  CollectionReference,
  doc,
  getDoc,
  getDocs,
  deleteDoc,
  limit,
  query,
  setDoc,
  updateDoc,
  where,
  deleteField,
  onSnapshot,
  DocumentSnapshot
} from 'firebase/firestore';
import { ShipEngineValidateResponse } from '../shipengine';
import {
  CreateUserDocumentArgs,
  GetUserArgs,
  UpdateUserDocArgs,
  updateUserDocArgsSchema,
  UpdateUserRoleArgs,
} from './user.types';

export const userRef = collection(
  db,
  'users'
) as CollectionReference<UserDocument>;

const userCol = collection(db, 'users') as CollectionReference<UserDocument>;
const getRef = (uid: string) => doc<UserDocument>(userCol, uid);

export async function getUserDocument(
  args: GetUserArgs
): Promise<UserDocument | null> {
  if (!args.uid) return null;
  const docSnap = await getDoc(doc(db, 'users', args.uid));
  const user = (docSnap.data() as UserDocument) ?? null;
  return user;
}

export function listenToUserDocument(uid: string, callback: (doc: UserDocument | null) => void): () => void {
  if (!uid) return () => {};

  const docRef = doc(db, 'users', uid);

  const unsubscribe = onSnapshot(docRef, (snapshot) => {
    if (snapshot.exists()) {
      callback(snapshot.data() as UserDocument);
    } else {
      callback(null);
    }
  });

  return unsubscribe;
}




export async function getUserByAccountId(accountId: string) {
  const q = query(userRef, where('accountId', '==', accountId), limit(1));
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export async function getUserById(uid: string) {
  const user = await getDoc(getRef(uid));
  return user.data();
}

export async function getUsersByIds(uids: string[]): Promise<UserDocument[]> {
  const q = query(userRef, where('uid', 'in', uids.slice(0, 10)));
  const snap = await getDocs(q);
  return snap.docs.map((doc) => doc.data());
}

export async function getUserByEmail(email: string) {
  const q = query(userRef, where('email', '==', email), limit(1));
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export function updateUserByKey(
  uid: string,
  key: keyof UserDocument,
  value: any
): Promise<void> {
  return updateDoc(getRef(uid), { [key]: value });
}

export async function removeUserKey(uid: string, key: keyof UserDocument) {
  return await updateDoc(getRef(uid), {
    [key]: deleteField(),
  });
}

export function addUserAddress(uid: string, address: AddressDocument) {
  return updateDoc(getRef(uid), { addresses: arrayUnion(address) });
}

export async function removeUserAddress(uid: string, address: AddressDocument) {
  // check if theres only one address
  const user = await getUserById(uid);
  if (!user) return;
  if (!user.addresses || user.addresses.length === 1) return;

  return updateDoc(getRef(uid), { addresses: arrayRemove(address) });
}

export function deleteUserDocument(
  uid: string
): Promise<void> {
  return deleteDoc(getRef(uid));
}

export async function getUserByUsernameSlug(
  username_slug: string
): Promise<UserDocument | null> {
  if (!username_slug) return null;
  const q = query(
    userRef,
    where('username_slug', '==', username_slug),
    limit(1)
  );
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}
export async function getUserByUsername(
  username: string
): Promise<UserDocument | null> {
  if (!username) return null;
  const q = query(userRef, where('username', '==', username), limit(1));
  const docSnap = await getDocs(q);
  const user = docSnap.size ? docSnap.docs[0].data() : null;
  return user;
}

export async function createUserDocument(
  args: CreateUserDocumentArgs,
  method: AuthProvider | 'email'
) {
  await setDoc(doc(db, 'users', args.uid), args);
  await completeRegistration(args.uid, args.email, method);
}

export async function updateUserRole(args: UpdateUserRoleArgs) {
  if (!args.uid) return null;
  const userRef = doc(db, 'users', args.uid);
  await updateDoc(userRef, {
    roles: args.newRole,
  });
}

export async function updateUsername(uid: string, username: string) {
  const userRef = doc(db, 'users', uid);
  const username_slug = await getUniqueSlug(username, uid);
  return updateDoc(userRef, { username, username_slug });
}

export async function updateUserDoc(uid: string, args: UpdateUserDocArgs) {
  if (!uid) return null;
  const userRef = doc(db, 'users', uid);
  const updatedUser = updateUserDocArgsSchema.parse({
    ...args,
  });
  await updateDoc(userRef, updatedUser);
}

export async function validateAddresses(
  addresses: AddressDocument[]
): Promise<ShipEngineValidateResponse[]> {
  const res = await httpsCallable<AddressDocument[], any>(
    functions,
    'validateAddress'
  )(addresses);
  return res.data;
}

export function canChangeUserName(user: UserDocument) {
  // can only change a name once every 30 days
  if (user.username_changed) {
    const lastChange = new Date(user.username_changed);
    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    return lastChange < thirtyDaysAgo;
  }
  return true;
}

export async function getUserToken(emailOrUsername: string, uid?: string) {
  if (!uid) {
    const q = query(userRef, where('email', '==', emailOrUsername), limit(1));
    const q2 = query(
      userRef,
      where('username', '==', emailOrUsername),
      limit(1)
    );
    const [docSnap, docSnap2] = await Promise.all([getDocs(q), getDocs(q2)]);
    const foundDoc = !docSnap.empty
      ? docSnap
      : !docSnap2.empty
      ? docSnap2
      : null;
    if (!foundDoc) return null;
    uid = foundDoc.docs[0].id;
  }
  const { data } = await httpsCallable<{ uid: string }, { token: string }>(
    functions,
    'impersonateUser'
  )({ uid });
  return data.token;
}

export async function updateAuthUser(
  uid: string,
  emailVerified?: boolean,
  disabled?: boolean,
  email?: string
) {
  return httpsCallable<
    {
      uid: string;
      emailVerified?: boolean;
      disabled?: boolean;
      email?: string;
    },
    null
  >(
    functions,
    'updateAuthUser'
  )({
    uid,
    ...(emailVerified !== undefined && { emailVerified }),
    ...(disabled !== undefined && { disabled }),
    ...(email && { email }),
  });
}

export function generateUsername(name: string | null): string {
  // generate a username (last name + first initial of first name)
  if (!name) return 'user' + Math.floor(Math.random() * 1000000);
  const [firstName, lastName] = name.split(' ');
  return slugify(
    `${lastName}${firstName[0]}-${Math.floor(Math.random() * 1000000)}}`
  );
}

export function getValidUserDocument(
  uid: string,
  email: string,
  form: {
    firstName: string;
    lastName: string;
  }
): UserDocument {
  const docData = userDocumentSchema.parse({
    name: `${form.firstName} ${form.lastName}`,
    first: form.firstName,
    last: form.lastName,
    phone: '',
    country: '',
    state: '',
    account_activated: false,
    badges: [
      'New User'
    ],
    badge_counts: [],
    brands: [],
    created: Date.now(),
    email,
    favorites: [],
    following: [],
    from_web: false,
    hide_status: false,
    last_active: Date.now(),
    opted_out: false,
    photo: `https://ui-avatars.com/api/?background=random&length=2&name=${form.firstName}&size=256`,
    rating: 0,
    reviews: 0,
    uid,
    username: `user-${uid.slice(0, 5)}`,
    username_slug: `user-${uid.slice(0, 5)}`,
    username_changed: 0,
    stripe: { requests: [] },
    permissions: {
      is_verified: false,
      is_banned: false,
      is_seller: false,
      is_fraudulent: false,
      is_email_verified: false,
      is_restricted: false,
      can_message: false,
      can_buy: false,
      can_sell: false,
    }
  });
  return docData;
}

export async function getUserAddresses(
  user: UserDocument,
  realUserUid?: string
): Promise<AddressDocument[]> {
  if (user.uid === process.env.NEXT_PUBLIC_SUPPORT_ID && realUserUid) {
    const realUser = await getUserDocument({ uid: realUserUid });
    return realUser?.addresses ?? [];
  }
  return user.addresses ?? [];
}

export async function getUniqueSlug(
  username: string,
  uid?: string
): Promise<string> {
  const q = query(
    userRef,
    where('username_slug', '==', slugify(username)),
    limit(1)
  );
  const docSnap = await getDocs(q);
  if (docSnap.size) {
    const str = uid ? uid.slice(0, 5) : Math.floor(Math.random() * 1000000);
    return slugify(`${username}-${str}`);
  }
  return slugify(username);
}

type NexusState = {
  country: string;
  country_code: string;
  region: string;
  region_code: string;
};

export async function getNexusStates(): Promise<NexusState[]> {
  const ref = collection(db, 'nexus_states');
  const q = query(ref);
  const docSnap = await getDocs(q);
  return docSnap.docs.map((d) => d.data()) as NexusState[];
}

export async function generateAccountLink(field:string, type: string): Promise<any> {
  const res = await httpsCallable(
    functions,
    'stripe-generateAccountLink'
  )({field: field, type: type});
  return res.data;
}

export async function validateSellerAccount(): Promise<any> {
  const res = await httpsCallable(
    functions,
    'stripe-validateSellerAccount'
  )();
  return res.data;
}