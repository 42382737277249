import { Dove, Rabbit, Snail } from './animals';
import AppleBrand from './apple';
import { ArchiveBox } from './archivebox';
import { ArrowFatLineUp, ArrowUpRight, LeftArrow, RightArrow } from './arrow';
import { Asterisk } from './asterisk';
import {
  CheckEmail,
  ForgotPassword,
  ResetPassword,
  VerifyEmail,
} from './authIcons';
import { Bag, BagFeature } from './bag';
import { CameraFeature } from './camera';
import { Cancel } from './cancel';
import { Card, CardWithFields } from './card';
import { Cart } from './cart';
import { Cash } from './cash';
import {
  CameraLens,
  Computer,
  DigitalCamera,
  Drone,
  Lighting,
  VideoCamera,
} from './categories';
import { ChatFeature } from './chat';
import { Check } from './check';
import { CheckCircle } from './CheckCircle';
import { Checkmark } from './checkmark';
import { Checkpoint } from './checkpoint';
import {
  ChevronDoubleLeft,
  ChevronDoubleRight,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
} from './chevron';
import { ClockFeature, ClockFilled, ClockOutline } from './clock';
import { Close } from './close';
import { Coin } from './coin';
import { Copy } from './copy';
import { AmericanExpress, MasterCard, Visa } from './credit-cards';
import { Dashboard } from './dashboard';
import { Discount } from './discount';
import { DocumentSmall } from './document-small';
import { DollarCircle } from './dollar';
import { DotsVertical } from './DotsVertical';
import { Download } from './download';
import { HappyEmoji, NeutralEmoji, SadEmoji } from './emoji';
import { EmptyWallet } from './EmptyWallet';
import { Expand } from './expand';
import { Eye, EyeOutline } from './eye';
import EyeSlash from './eyeSlash';
import { FileArrowDown } from './file';
import { Filter } from './filter';
import { Flag } from './flag';
import { Gear } from './gear';
import { GlobeSimple } from './globe';
import { Google } from './google';
import { Grid } from './grid';
import { Hand } from './hand';
import { Heart, HeartStraight } from './heart';
import { Lightning } from './lightning';
import { Like, LikeShapes } from './like';
import { List, ListOutline } from './list';
import { Location, LocationFilled } from './location';
import { LockCircle } from './lock';
import { Logo, LogoIcon, LogoText } from './logo';
import { Logout } from './logout';
import { LogoWithoutText } from './logoWithoutText';
import { Menu } from './menu';
import { Message } from './message';
import { MessageStar } from './message-star';
import Minus from './minus';
import { Note } from './note';
import { Package, PackageOutline } from './package';
import { Page } from './page';
import { Paperclip } from './PaperClip';
import { PauseCircle } from './pause-circle';
import { Payout } from './payout';
import { PencilSimple } from './pencil';
import { Person, PersonCheck, PersonOutline } from './person';
import { Phone } from './phone';
import Plus from './plus';
import { Printer } from './printer';
import { Protect } from './protect';
import { Pushpin } from './pushpin';
import { Rating } from './rating';
import { Refund } from './refund';
import { Return } from './return';
import { Rotate, ThreeDRotate } from './rotate';
import { Sale, SaleFeature } from './sale';
import { Search } from './search';
import { SecuritySafe } from './security';
import {
  Camera,
  Delivery,
  FreeShipping,
  GetMoney,
  HighValue,
  Packaging,
  Photos,
} from './seller';
import { Send } from './send';
import { Share, ShareAlt, Clipboard } from './share';
import { ShieldCheck, ShieldStar } from './shields';
import { Shipping } from './shipping';
import { FedEx } from './shipping-carries';
import { ShootingStar } from './shootingStar';
import { Shop } from './shop';
import { ShoppingBag } from './shopping-bag';
import { SMS } from './sms';
import {
  Email,
  Facebook,
  FacebookBrand,
  GoogleBrand,
  Instagram,
  MessengerBrand,
  Pinterest,
  TwitterBrand,
  WhatsAppBrand,
  Youtube,
} from './socials';
import { Star } from './star';
import { Storefront } from './storefront';
import { Support } from './support';
import { Task } from './task';
import { Trash } from './trash';
import { Truck, TruckFeature } from './truck';
import { Warning } from './warning';
import { Wishlist } from './wishlist';
import { Affirm } from './affirm';
import { MiniShoppingBag } from './miniShoppingBag';
import { UPS, USPS } from './carriers';
import { Settings } from './settings';
import { EmailFeature } from './email';
import { ChattingApp } from './chattingApp';
import { Smartphone } from './smartphone';
import { Information } from './information';
import RefundSmall from './refund-small';
import Plane from './plane';

// category icons
export const ComputerIcon = Computer;
export const DroneIcon = Drone;
export const LightingIcon = Lighting;
export const VideoCameraIcon = VideoCamera;
export const CameraLensIcon = CameraLens;
export const DigitalCameraIcon = DigitalCamera;

export const FacebookIcon = Facebook;
export const InstagramIcon = Instagram;
export const YoutubeIcon = Youtube;
export const PinterestIcon = Pinterest;
export const CartIcon = Cart;
export const SearchIcon = Search;
export const CheckmarkIcon = Checkmark;
export const ChevronDownIcon = ChevronDown;
export const ChevronRightIcon = ChevronRight;
export const ChevronLeftIcon = ChevronLeft;
export const ChevronDoubleLeftIcon = ChevronDoubleLeft;
export const ChevronDoubleRightIcon = ChevronDoubleRight;
export const ChevronUpIcon = ChevronUp;
export const HeartIcon = Heart;
export const HeartStraightIcon = HeartStraight;
export const StorefrontIcon = Storefront;
export const PhoneIcon = Phone;
export const EyeIcon = Eye;
export const EyeOutlineIcon = EyeOutline;
export const LeftArrowIcon = LeftArrow;
export const RightArrowIcon = RightArrow;
export const ArrowUpRightIcon = ArrowUpRight;
export const ArrowFatLineUpIcon = ArrowFatLineUp;
export const ExpandIcon = Expand;
export const CloseIcon = Close;
export const MenuIcon = Menu;
export const PersonIcon = Person;
export const PersonOutlineIcon = PersonOutline;
export const PersonCheckIcon = PersonCheck;
export const IconLogo = LogoIcon;
export const TextLogo = LogoText;
export const FullLogo = Logo;
export const LogoWithoutTextIcon = LogoWithoutText;
export const StarIcon = Star;
export const GoogleIcon = Google;
export const ProtectIcon = Protect;
export const CoinIcon = Coin;
export const PageIcon = Page;
export const ListIcon = List;
export const ListOutlineIcon = ListOutline;
export const GridIcon = Grid;
export const FilterIcon = Filter;
export const TrashIcon = Trash;
export const PlusIcon = Plus;
export const MinusIcon = Minus;
export const ShieldStarIcon = ShieldStar;
export const ShieldCheckIcon = ShieldCheck;
export const FacebookBrandIcon = FacebookBrand;
export const MessengerBrandIcon = MessengerBrand;
export const EmailIcon = Email;
export const GoogleBrandIcon = GoogleBrand;
export const AppleBrandIcon = AppleBrand;
export const TwitterBrandIcon = TwitterBrand;
export const WhatsAppBrandIcon = WhatsAppBrand;
export const GearIcon = Gear;
export const SaleIcon = Sale;
export const SettingsIcon = Settings;
export const SaleFeatureIcon = SaleFeature;
export const LockCircleIcon = LockCircle;
export const DiscountIcon = Discount;
export const TruckIcon = Truck;
export const TruckFeatureIcon = TruckFeature;
export const MessageIcon = Message;
export const CheckIcon = Check;
export const CardIcon = Card;
export const CardWithFieldsIcon = CardWithFields;
export const EyeSlashIcon = EyeSlash;
export const DashboardIcon = Dashboard;
export const TaskIcon = Task;
export const NoteIcon = Note;
export const ShopIcon = Shop;
export const DollarCircleIcon = DollarCircle;
export const SMSIcon = SMS;
export const BagIcon = Bag;
export const BagFeatureIcon = BagFeature;
export const LogoutIcon = Logout;
export const VerifyEmailIcon = VerifyEmail;
export const CheckEmailIcon = CheckEmail;
export const ForgotPasswordIcon = ForgotPassword;
export const ResetPasswordIcon = ResetPassword;
export const PencilSimpleIcon = PencilSimple;
export const PackagingIcon = Packaging;
export const DeliveryIcon = Delivery;
export const GetMoneyIcon = GetMoney;
export const CameraIcon = Camera;
export const PhotosIcon = Photos;
export const HighValueIcon = HighValue;
export const FreeShippingIcon = FreeShipping;
export const DownloadIcon = Download;
export const PackageIcon = Package;
export const PackageOutlineIcon = PackageOutline;
export const RefundIcon = Refund;
export const ShippingIcon = Shipping;
export const ReturnIcon = Return;
export const RatingIcon = Rating;
export const PayoutIcon = Payout;
export const GlobeSimpleIcon = GlobeSimple;
export const ShareIcon = Share;
export const ShareAltIcon = ShareAlt;
export const ClipboardIcon = Clipboard;
export const FlagIcon = Flag;
export const HandIcon = Hand;
export const LocationIcon = Location;
export const LocationFilledIcon = LocationFilled;
export const EmptyWalletIcon = EmptyWallet;
export const ShootingStarIcon = ShootingStar;
export const LikeIcon = Like;
export const LikeShapesIcon = LikeShapes;
export const RotateIcon = Rotate;
export const ThreeDRotateIcon = ThreeDRotate;
export const CameraFeatureIcon = CameraFeature;
export const ChatFeatureIcon = ChatFeature;
export const DotsVerticalIcon = DotsVertical;
export const PaperclipIcon = Paperclip;
export const SendIcon = Send;
export const CancelIcon = Cancel;
export const ShoppingBagIcon = ShoppingBag;
export const DocumentIcon = DocumentSmall;
export const FileArrowDownIcon = FileArrowDown;
export const WishlistIcon = Wishlist;
export const AffirmIcon = Affirm;
export const WarningIcon = Warning;
export const InformationIcon = Information;
export const PauseCircleIcon = PauseCircle;
export const CashIcon = Cash;
export const ClockOutlineIcon = ClockOutline;
export const ClockFeatureIcon = ClockFeature;
export const ClockFilledIcon = ClockFilled;
export const ArchiveBoxIcon = ArchiveBox;
export const CheckpointIcon = Checkpoint;
export const SadEmojiIcon = SadEmoji;
export const HappyEmojiIcon = HappyEmoji;
export const NeutralEmojiIcon = NeutralEmoji;
export const SnailIcon = Snail;
export const RabbitIcon = Rabbit;
export const DoveIcon = Dove;
export const PushpinIcon = Pushpin;
export const AsteriskIcon = Asterisk;
export const VisaIcon = Visa;
export const MasterCardIcon = MasterCard;
export const AmericanExpressIcon = AmericanExpress;
export const SupportIcon = Support;
export const MessageStarIcon = MessageStar;
export const SecuritySafeIcon = SecuritySafe;
export const CheckCircleIcon = CheckCircle;
export const FedExIcon = FedEx;
export const CopyIcon = Copy;
export const PrinterIcon = Printer;
export const LightningIcon = Lightning;
export const MiniShoppingBagIcon = MiniShoppingBag;
export const UPSIcon = UPS;
export const USPSIcon = USPS;
export const ChattingAppIcon = ChattingApp;
export const EmailFeatureIcon = EmailFeature;
export const SmartphoneIcon = Smartphone;
export const RefundSmallIcon = RefundSmall;
export const PlaneIcon = Plane;
