import { countries } from 'countries-list';

export const STATES = [
  'AL',
  'AK',
  'AZ',
  'AR',
  'CA',
  'CO',
  'CT',
  'DE',
  'DC',
  'FL',
  'GA',
  'HI',
  'ID',
  'IL',
  'IN',
  'IA',
  'KS',
  'KY',
  'LA',
  'ME',
  'MD',
  'MA',
  'MI',
  'MN',
  'MS',
  'MO',
  'MT',
  'NE',
  'NV',
  'NH',
  'NJ',
  'NM',
  'NY',
  'NC',
  'ND',
  'OH',
  'OK',
  'OR',
  'PA',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VT',
  'VA',
  'WA',
  'WV',
  'WI',
  'WY',
] as const;

export type State = (typeof STATES)[number];

export const CANADA_PROVINCES = [
  'AB',
  'BC',
  'MB',
  'NB',
  'NL',
  'NT',
  'NS',
  'NU',
  'ON',
  'PE',
  'QC',
  'SK',
  'YT',
] as const;

export type CanadaProvince = (typeof CANADA_PROVINCES)[number];

export const AUSTRALIA_STATES = [
  'ACT',
  'NSW',
  'NT',
  'QLD',
  'SA',
  'TAS',
  'VIC',
  'WA',
] as const;

export type AustraliaState = (typeof AUSTRALIA_STATES)[number];

export const getStates = (country: string) => {
  switch (country) {
    case 'United States':
      return STATES;
    case 'Canada':
      return CANADA_PROVINCES;
    case 'Australia':
      return AUSTRALIA_STATES;
    default:
      return [];
  }
};

export const getCountries = () => {
  const priority = ["US", "CA"];

  const countryList = Object.entries(countries)
    .map(([code, country]) => ({
      label: country.name,
      value: code,
      id: code,
    }))
    .sort((a, b) => {
      // Prioritize US and CA
      if (priority.includes(a.value) && priority.includes(b.value)) {
        return priority.indexOf(a.value) - priority.indexOf(b.value);
      }
      if (priority.includes(a.value)) return -1;
      if (priority.includes(b.value)) return 1;

      // Default to alphabetical sorting
      return a.label.localeCompare(b.label);
    });

  // Insert a separator item after US and CA
  const separator = { label: "-", value: "separator", id: "separator" };
  const priorityCountries = countryList.filter((c) => priority.includes(c.value));
  const otherCountries = countryList.filter((c) => !priority.includes(c.value));

  return [...priorityCountries, separator, ...otherCountries];
};

// these will most likely get updated
// look at postman res from: https://stripe.com/docs/connect/required-verification-information
const stripeConnectCountries = [
  {
    code: 'AU',
    name: 'Australia',
    beta: false,
  },
  {
    code: 'AT',
    name: 'Austria',
    beta: false,
  },
  {
    code: 'BE',
    name: 'Belgium',
    beta: false,
  },
  {
    code: 'BR',
    name: 'Brazil',
    beta: false,
  },
  {
    code: 'BG',
    name: 'Bulgaria',
    beta: false,
  },
  {
    code: 'CA',
    name: 'Canada',
    beta: false,
  },
  {
    code: 'HR',
    name: 'Croatia',
    beta: false,
  },
  {
    code: 'CY',
    name: 'Cyprus',
    beta: false,
  },
  {
    code: 'CZ',
    name: 'Czech Republic',
    beta: false,
  },
  {
    code: 'DK',
    name: 'Denmark',
    beta: false,
  },
  {
    code: 'EE',
    name: 'Estonia',
    beta: false,
  },
  {
    code: 'FI',
    name: 'Finland',
    beta: false,
  },
  {
    code: 'FR',
    name: 'France',
    beta: false,
  },
  {
    code: 'DE',
    name: 'Germany',
    beta: false,
  },
  {
    code: 'GI',
    name: 'Gibraltar',
    beta: false,
  },
  {
    code: 'GR',
    name: 'Greece',
    beta: false,
  },
  {
    code: 'HK',
    name: 'Hong Kong',
    beta: false,
  },
  {
    code: 'HU',
    name: 'Hungary',
    beta: false,
  },
  {
    code: 'IN',
    name: 'India',
    beta: true,
  },
  {
    code: 'IE',
    name: 'Ireland',
    beta: false,
  },
  {
    code: 'IT',
    name: 'Italy',
    beta: false,
  },
  {
    code: 'JP',
    name: 'Japan',
    beta: false,
  },
  {
    code: 'LV',
    name: 'Latvia',
    beta: false,
  },
  {
    code: 'LI',
    name: 'Liechtenstein',
    beta: false,
  },
  {
    code: 'LT',
    name: 'Lithuania',
    beta: false,
  },
  {
    code: 'LU',
    name: 'Luxembourg',
    beta: false,
  },
  {
    code: 'MY',
    name: 'Malaysia',
    beta: false,
  },
  {
    code: 'MT',
    name: 'Malta',
    beta: false,
  },
  {
    code: 'MX',
    name: 'Mexico',
    beta: false,
  },
  {
    code: 'NL',
    name: 'Netherlands',
    beta: false,
  },
  {
    code: 'NZ',
    name: 'New Zealand',
    beta: false,
  },
  {
    code: 'NO',
    name: 'Norway',
    beta: false,
  },
  {
    code: 'PL',
    name: 'Poland',
    beta: false,
  },
  {
    code: 'PT',
    name: 'Portugal',
    beta: false,
  },
  {
    code: 'RO',
    name: 'Romania',
    beta: false,
  },
  {
    code: 'SG',
    name: 'Singapore',
    beta: false,
  },
  {
    code: 'SK',
    name: 'Slovakia',
    beta: false,
  },
  {
    code: 'SI',
    name: 'Slovenia',
    beta: false,
  },
  {
    code: 'ES',
    name: 'Spain',
    beta: false,
  },
  {
    code: 'SE',
    name: 'Sweden',
    beta: false,
  },
  {
    code: 'CH',
    name: 'Switzerland',
    beta: false,
  },
  {
    code: 'TH',
    name: 'Thailand',
    beta: false,
  },
  {
    code: 'AE',
    name: 'United Arab Emirates',
    beta: false,
  },
  {
    code: 'GB',
    name: 'United Kingdom',
    beta: false,
  },
  {
    code: 'US',
    name: 'United States',
    beta: false,
  },
] as const;

export const getConnectCountries = () => {
  return stripeConnectCountries
    .map(({ code, name }) => ({
      label: name,
      value: code,
      id: code,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

export const southAmCodes = [
  'AR',
  'BO',
  'BR',
  'CL',
  'CO',
  'EC',
  'FK',
  'GF',
  'GY',
  'PY',
  'PE',
  'SR',
  'UY',
  'VE',
];
export const europeCodes = [
  'AT',
  'BE',
  'BG',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GB',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LT',
  'LU',
  'LV',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
];
