import { z } from 'zod';

export const cartItemSchema = z.object({
  product_id: z.string(),
  product_sku: z.string(),
  product_title: z.string(),
  product_image: z.string(),
  product_link: z.string(),
  product_cost: z.number(),
  product_brand: z.string(),
  product_categories: z.array(z.string()),
  product_master: z.string(),
  external_source: z.string().default('gearfocus'),
  seller_id: z.string().default(''), // TODO: CLEAN UP DATA--THIS SHOULDN'T BE OPTIONAL OR HAVE DEFAULTS
  qty: z.number().default(1),
  account_id: z.string().default(''), // TODO: CLEAN UP DATA--THIS SHOULDN'T BE OPTIONAL OR HAVE DEFAULTS
  shipping_cost: z.number().optional(),
  is_flat_rate: z.boolean().default(false),
  rate_id: z.string().optional(),
});

export const cartSchema = z.object({
  created: z.number().default(Date.now()),
  last_updated: z.number().default(Date.now()),
  product_ids: z.array(z.string()).default([]),
  shipping: z.number().default(0),
  subtotal: z.number().default(0),
  total: z.number().default(0),
  uid: z.string(),
  items: z.array(cartItemSchema).default([]),
});

export type Cart = z.infer<typeof cartSchema>;
export type CartItem = z.infer<typeof cartItemSchema>;
