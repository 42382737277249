import { Availability } from '@c/dashboard/pages/ListingsPage';
import { db, functions, httpsCallable } from '@util/firebase';
import { searchClient } from '@util/getAlgolia';
import { BaseGetProductsArgs } from '@util/getPropsFromConext';
import { nonNullable } from '@util/index';
import { getBrand } from '@util/maps/brands';
import { CATEGORY_LABEL } from '@util/maps/categories/labels';
import { Condition, CONDITION_LABEL_MAP } from '@util/maps/conditions';
import { ReasonTypes } from '@util/maps/report-reasons';
import { Category } from '@util/types/categories';
import {
  ProductDocument,
  productDocumentSchema,
} from '@util/types/firestore/products';
import { UserDocument } from '@util/types/firestore/users';
import { Pagination } from '@util/types/paginator';
import {
  arrayRemove,
  arrayUnion,
  collection,
  CollectionReference,
  deleteField,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  increment,
  limit,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
} from 'firebase/firestore';
import { CuratedListDocument } from './products.types';

import { ConditionFilter, GetProductArgs } from './products.types';
export const DEFAULT_LIMIT = 12;

export const productsRef = collection(
  db,
  'products'
) as CollectionReference<ProductDocument>;

export const listsRef = collection(
  db,
  'lists'
) as CollectionReference<CuratedListDocument>;

export const usersRef = collection(
  db,
  'users'
) as CollectionReference<UserDocument>;

const defaultPagination: Pagination = {
  page: 1,
  totalPages: 1,
  itemsPerPage: 24,
  totalResults: 0,
};

export const getProductsByIdQuery = (ids: string[]) =>
  query(productsRef, where('id', 'in', [...ids]));

export const getAuctionsQuery = () =>
  query(
    productsRef,
    where('is_auction', '==', true),
    where('out_of_stock', '==', false),
    // order by ending soonest
    orderBy('end_time', 'asc'),
    limit(10)
  );

export type ApiResponsePaginated<T> = {
  results: T[];
  brands?: string[];
  pageInfo: Pagination;
};
export type ApiResponse<T> = {
  results: T;
};

export async function getProductBySlug(
  args: GetProductArgs
): Promise<ApiResponse<ProductDocument | null>> {
  const q = query(productsRef, where('slug', '==', args.slug), limit(1));
  const snapshot = await getDocs(q);
  const data = snapshot.docs?.[0]?.data() ?? null;
  return { results: data };
}
export async function getProductById(id: string) {
  const d = doc(productsRef, id);
  const snapshot = await getDoc(d);
  return snapshot.data() ?? null;
}

export async function getProductSlugs(): Promise<ApiResponse<string[]>> {
  const docsResponse = await getDocs(productsRef);
  const slugs = docsResponse.docs.map((doc) => doc.get('slug')).filter(Boolean);
  return {
    results: slugs,
  };
}

export async function getMostRecentProductSlugs(): Promise<
  ApiResponse<string[]>
> {
  const q = query(productsRef, orderBy('date_added', 'desc'), limit(1000));
  const docsResponse = await getDocs(q);
  const slugs = docsResponse.docs.map((doc) => doc.get('slug')).filter(Boolean);
  return {
    results: slugs,
  };
}

export async function getProductsByIds(
  ids: string[]
): Promise<ProductDocument[]> {
  if (ids.length === 0) return [];
  const q = query(productsRef, where('id', 'in', ids));
  const snapshot = await getDocs(q);
  return snapshot.docs.map((d) => d.data());
}

export async function getProducts(
  args: BaseGetProductsArgs
): Promise<ApiResponsePaginated<ProductDocument>> {
  if (argsRequireAlgolia(args)) {
    return getProductsFromAlgolia(args);
  } 

  const resultLimit = args.limit ?? DEFAULT_LIMIT;
  const brands = args.brand
    ? [args.brand]
    : args.brands?.map((b) => getBrand(b)) ?? [];
 

  const constraintsUnlimited = [
    where('out_of_stock', '==', false),
    where('is_expired', '==', false),
    where('is_draft', '==', false),
    where('is_verified', '==', true),
    args.master ? where('master', '==', args.master) : null,
    args.on_sale ? where('on_sale', '==', true) : null,
    args.is_featured ? where('is_featured', '==', true) : null,
    args.is_auction ? where('is_auction', '==', true) : null,
    args.seller_id ? where('seller_id', '==', args.seller_id) : null,
    args.curated_list
      ? where('curated_lists', 'array-contains', args.curated_list)
      : null,      
    getCategoryConstraint(args.category ? args.category.name : null),
    getMinPriceConstraint(args.minPrice),
    getMaxPriceConstraint(args.maxPrice),
    getSortConstraint(args.sort),
    getBrandsConstraint(brands),
    getConditionsConstraint(args.conditions),
  ].filter(nonNullable);

  const constraintsLimited = [...constraintsUnlimited, limit(resultLimit)];

  const qLimited = query(productsRef, ...constraintsLimited);
  const qUnlimited = query(productsRef, ...constraintsUnlimited);

  const getDocsPromise = getDocs(qLimited);
  const getTotalResultsPromise = getCountFromServer(qUnlimited);

  const [getDocsSnapshot, totalResultsSnapshot] = await Promise.all([
    getDocsPromise,
    getTotalResultsPromise,
  ]);
  const docsResponse = getDocsSnapshot.docs.map((x) => x.data());
  const totalResultsResponse = totalResultsSnapshot.data().count;

  const totalPages = Math.ceil(totalResultsResponse / args.limit) || 1;
  const page = args.page > totalPages ? totalPages : args.page;

  return {
    results: docsResponse,
    brands: getBrandsFromResult(docsResponse),
    pageInfo: {
      ...defaultPagination,
      totalResults: totalResultsResponse,
      page,
      totalPages,
    },
  };
}

export const getAllProductsPaginated = async (args: any) => {
  const { lastKey, productStatus } = args;

  const limitValue = 10;
  const lastDoc = lastKey ? await getDoc(doc(productsRef, lastKey)) : null;

  const filterConstraints = [
    ...(productStatus !== 'is_verified'
      ? [where(productStatus, '==', true)]
      : [where(productStatus, '==', false)]),
  ];

  const getAll = (lastDoc: any) =>
    query(
      productsRef,
      orderBy('created', 'desc'),
      ...(lastDoc ? [startAfter(lastDoc)] : []),
      ...filterConstraints,
      limit(limitValue)
    );

  const q = getAll(lastDoc)
  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => {
    return doc.data() //productDocumentSchema.parse(doc.data());
  });
  return { results: data };
};

export async function getAllUnverified(arg: string): Promise<any> {
  const q = query(collection(db, 'products'), where(arg, '==', false));
  const snapshot = await getDocs(q);

  const products = snapshot.docs.map((doc) => ({
    ...doc.data(),
  }));

  return { products };
}

export async function getProductsFromAlgolia(args: BaseGetProductsArgs) {
  let filters = '';
  if (args.category) filters += `categories:"${args.category.name}" AND `;
  if (args.seller_id) filters += `seller_id:"${args.seller_id}" AND `;
  if (args.is_featured) filters += 'is_featured:true AND ';
  if (args.on_sale) filters += 'on_sale:true AND ';
  if (args.curated_list) filters += `curated_lists:"${args.curated_list}" AND `;
  
  if (args.minPrice && args.maxPrice)
    filters += `price:${args.minPrice} TO ${args.maxPrice} AND `;
  else if (args.minPrice) filters += `price>=${args.minPrice} AND `;
  else if (args.maxPrice) filters += `price<=${args.maxPrice} AND `;
  
  if (args.brands?.length) {
    filters += '(';
    filters += args.brands
      .map((b) => `brand:"${getBrand(b)}" OR `)
      .join('')
      .slice(0, -4);
    filters += ') AND ';
  } else if (args.brand) filters += `brand:"${getBrand(args.brand)}" AND `;

  if (args.conditions?.length) {
    filters += '(';
    filters += args.conditions
      .map((c) => `condition:"${CONDITION_LABEL_MAP[c]}" OR `)
      .join('')
      .slice(0, -4);
    filters += ') AND ';
  }

  if (filters.endsWith('AND ')) filters = filters.slice(0, -5);
  const index =
    args.sort === 'priceAsc'
      ? 'products_low_price'
      : args.sort === 'priceDesc'
      ? 'products_high_price'
      : 'products';

  const res = await searchClient
    .initIndex(index)
    .search<ProductDocument>(args.keyword ?? '', {
      ...(filters && { filters }),
      hitsPerPage: args.limit,
      page: Math.max(0, args.page - 1),
    });

  return {
    results: res.hits,
    brands: getBrandsFromResult(res.hits),
    pageInfo: {
      page: args.page > res.nbPages ? res.nbPages : args.page,
      totalPages: res.nbPages,
      itemsPerPage: res.hitsPerPage,
      totalResults: res.nbHits,
    },
  };
}

export async function getNewArrivals(
  l = DEFAULT_LIMIT
): Promise<ApiResponse<ProductDocument[]>> {
  const q = query(
    productsRef,
    where('out_of_stock', '==', false),
    where('is_auction', '==', false),
    where('is_expired', '==', false),
    where('is_verified', '==', true),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getFeaturedProducts(l = DEFAULT_LIMIT) {
  const q = query(
    productsRef,
    where('is_featured', '==', true),
    where('out_of_stock', '==', false),
    where('is_expired', '==', false),
    where('is_verified', '==', true),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getProductsByMaster(master: string) {
  try {
    const q = query(
      productsRef,
      where('out_of_stock', '==', false),
      where('is_expired', '==', false),
      where('is_verified', '==', true),
      where('master', '==', master),
      orderBy('date_added', 'desc'),
    );

    const { docs } = await getDocs(q);
    const results = docs.map((d) => d.data());
    return { results };
  } catch (error) {
    console.error('Error fetching products by master:', error);
    throw error;
  }
}

export async function getAuctions(l = DEFAULT_LIMIT) {
  const q = query(
    productsRef,
    where('is_auction', '==', true),
    where('out_of_stock', '==', false),
    // order by ending soonest
    orderBy('end_time', 'asc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getAllAuctionsBySellerId(seller_id: string) {
  const q = query(
    productsRef,
    where('seller_id', '==', seller_id),
    where('is_auction', '==', true),
    where('is_draft', '==', false),
    where('end_time', '>=', Date.now())
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getOnSale(l = DEFAULT_LIMIT) {
  const q = query(
    productsRef,
    where('on_sale', '==', true),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getRecentlySold(l = DEFAULT_LIMIT) {
  const q = query(
    productsRef,
    where('out_of_stock', '==', true),
    orderBy('sold_date', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export async function getRelatedProducts(
  product: ProductDocument,
  l = DEFAULT_LIMIT
) {
  let mostSpecific = product.categories[product.categories.length - 1];
  const q = query(
    productsRef,
    where('categories', 'array-contains', mostSpecific),
    where('brand', '==', product.brand),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );

  const snap = await getDocs(q);

  const results: ProductDocument[] = [];
  snap.docs.forEach((d) => {
    if (d.id !== product.id) results.push(d.data());
  });

  if (results.length < l && product.categories.length > 1) {
    mostSpecific = product.categories[product.categories.length - 2];
    const q3 = query(
      productsRef,
      where('categories', 'array-contains', mostSpecific),
      where('brand', '==', product.brand),
      where('out_of_stock', '==', false),
      orderBy('date_added', 'desc'),
      limit(l)
    );
    const { docs } = await getDocs(q3);
    docs.forEach((d) => {
      if (d.id !== product.id && !results.some((r) => r.id === d.id))
        results.push(d.data());
    });
  }

  if (results.length < l) {
    const q2 = query(
      productsRef,
      where('categories', 'array-contains', mostSpecific),
      where('out_of_stock', '==', false),
      orderBy('date_added', 'desc'),
      limit(l)
    );
    const { docs } = await getDocs(q2);
    docs.forEach((d) => {
      if (d.id !== product.id && !results.some((r) => r.id === d.id))
        results.push(d.data());
    });
  }
  if (results.length < l && product.categories.length > 1) {
    mostSpecific = product.categories[product.categories.length - 2];
    const q3 = query(
      productsRef,
      where('categories', 'array-contains', mostSpecific),
      where('out_of_stock', '==', false),
      orderBy('date_added', 'desc'),
      limit(l)
    );
    const { docs } = await getDocs(q3);
    docs.forEach((d) => {
      if (d.id !== product.id && !results.some((r) => r.id === d.id))
        results.push(d.data());
    });
  }
  return { results };
}

export async function getMoreFromThisSeller(
  product: ProductDocument,
  l = DEFAULT_LIMIT
): Promise<ProductDocument[]> {
  const q = query(
    productsRef,
    where('seller_id', '==', product.seller_id),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const snapshot = await getDocs(q);
  const products = snapshot.docs.map((doc) => doc.data()) ?? [];
  return products.filter((p) => p.id !== product.id);
}

export async function getSellerListingsPaginated(
  sellerId: string,
  isAdmin: boolean,
  lastKey?: string,
  category: Category | null = null,
  condition: Condition | null = null,
  availability: Availability | null = null
) {
  if (!sellerId && !isAdmin) return Promise.resolve({ results: [] });

  const limitValue = 12;
  const lastDoc = lastKey ? await getDoc(doc(productsRef, lastKey)) : null;

  const filterConstraints = [
    ...(category ? [where('category', '==', CATEGORY_LABEL[category])] : []),
    ...(condition
      ? [where('condition', '==', CONDITION_LABEL_MAP[condition])]
      : []),
    ...(availability ? [...getAvailabilityConstraint(availability)] : []),
  ];

  const baseQuery = (lastDoc: any) =>
    query(
      productsRef,
      orderBy('date_added', 'desc'),
      ...filterConstraints,
      ...(lastDoc ? [startAfter(lastDoc)] : []),
      limit(limitValue)
    );

  const q = isAdmin
    ? baseQuery(lastDoc)
    : lastDoc
    ? query(
        productsRef,
        where('seller_id', '==', sellerId),
        orderBy('date_added', 'desc'),
        ...filterConstraints,
        startAfter(lastDoc),
        limit(limitValue)
      )
    : query(
        productsRef,
        where('seller_id', '==', sellerId),
        orderBy('date_added', 'desc'),
        ...filterConstraints,
        limit(limitValue)
      );

  const snap = await getDocs(q);
  const results = snap.docs.map((d) => d.data());
  return { results };
}

export async function deleteListing(productId: string) {
  const docRef = doc(productsRef, productId);
  await updateDoc(docRef, { out_of_stock: true, date_added: deleteField() });
}

// we'll discuss this one
// prob use our ai guy
export async function getFreqPurchasedWith(
  categories: string[],
  l = DEFAULT_LIMIT
) {
  const q = query(
    productsRef,
    where(`category${categories.length - 1}`, '==', categories.pop()),
    where('out_of_stock', '==', false),
    orderBy('date_added', 'desc'),
    limit(l)
  );
  const { docs } = await getDocs(q);
  const results = docs.map((d) => d.data());
  return { results };
}

export function getProductId(): string {
  const newDocRef = doc(productsRef);
  const id = newDocRef.id;
  return id;
}

export async function addProductDoc(data: ProductDocument) {
  try {
    const parsedData = productDocumentSchema.parse(data);
    const docRef = doc(productsRef, parsedData.id);
    return setDoc(docRef, parsedData);
  } catch (e) {
    console.log("Error Adding Product", e)
    return null
  }  
}

export async function updateProductDoc(data: ProductDocument) {
  try {
    const parsedData = productDocumentSchema.parse(data);
    const docRef = doc(productsRef, parsedData.id);
    return await updateDoc(docRef, parsedData);
  } catch (e) {
    console.log("Error Updating Product", e)
  }  
}

// Constraints
function getCategoryConstraint(categoryQueryValue: string | null) {
  return !!categoryQueryValue
    ? where('categories', 'array-contains', categoryQueryValue)
    : null;
}

function getMinPriceConstraint(minPriceQueryValue?: number | null) {
  return !!minPriceQueryValue
    ? where('price', '>=', Number(minPriceQueryValue))
    : null;
}

function getMaxPriceConstraint(maxPriceQueryValue?: number | null) {
  return !!maxPriceQueryValue
    ? where('price', '<=', Number(maxPriceQueryValue))
    : null;
}

function getSortConstraint(sortQueryValue?: string | null) {
  return sortQueryValue === 'priceAsc'
    ? orderBy('price', 'asc')
    : sortQueryValue === 'priceDesc'
    ? orderBy('price', 'desc')
    : orderBy('date_added', 'desc');
}

function getBrandsConstraint(brandsQueryValue?: string[] | null) {
  if (brandsQueryValue?.length) {
    if (brandsQueryValue.length === 1) {
      return where('brand', '==', brandsQueryValue[0]);
    } else if (brandsQueryValue.length > 1) {
      return where('brand', 'in', brandsQueryValue);
    }
  }
  return null;
}

function getConditionsConstraint(conditionsQueryValue?: string[] | null) {
  if (conditionsQueryValue?.length)
    conditionsQueryValue = conditionsQueryValue.map(
      (c) => CONDITION_LABEL_MAP[c as ConditionFilter]
    );
  return !conditionsQueryValue?.length
    ? null
    : conditionsQueryValue.length === 1
    ? where('condition', '==', conditionsQueryValue[0])
    : where('condition', 'in', conditionsQueryValue);
}

// Helpers

function argsRequireAlgolia(args: BaseGetProductsArgs) {
  return (
    args.keyword ||
    args.page > 1 ||
    ((args.minPrice || args.maxPrice) && args.sort === 'newest') ||
    (args.brands &&
      args.brands.length > 1 &&
      args.conditions &&
      args.conditions.length > 1)
  );
}

export async function reportItem(
  id: string,
  reason: ReasonTypes,
  isUser: boolean
): Promise<void> {
  const col = isUser ? 'reports_users' : 'reports_products';
  const colRef = collection(db, col);
  const ref = doc(colRef, id);
  return setDoc(ref, { [reason]: increment(1) }, { merge: true });
}

export async function getOnSaleByCategory(category: string | undefined, l = DEFAULT_LIMIT) {
  if (!category || category === undefined) return null;

  try {
    const q = query(
      productsRef,
      where('categories', 'array-contains', category),
      where('on_sale', '==', true),
      where('out_of_stock', '==', false),
      where('is_expired', '==', false),
      where('is_draft', '==', false),
      orderBy('date_added', 'desc'),
      limit(l)
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((d) => d.data());
  } catch (error) {
    console.error("Error fetching on-sale products by category:", error);
    return null;
  }
}

export async function getCuratedListByCategory(list: string, category: string | undefined, l = DEFAULT_LIMIT) {
  if (!category || category === undefined) return null;

  try {
    const q = query(
      productsRef,
      where('curated_lists', 'array-contains', list),
      where('out_of_stock', '==', false),
      where('is_expired', '==', false),
      orderBy('date_added', 'desc'),
      limit(l)
    );
    const { docs } = await getDocs(q);

    const filteredDocs = docs.filter(doc => doc.data().categories.includes(category));
    return filteredDocs.map((d) => d.data());
  } catch (error) {
    console.error("Error fetching curated list by category:", error);
    return null;
  }
}

export async function updateFavoriteCount(
  id: string,
  isLiked = false
): Promise<void> {
  const ref = doc(productsRef, id);
  const val = isLiked ? -1 : 1;
  return updateDoc(ref, { favorite_count: increment(val) });
}

export async function updateProductByKey(
  id: string,
  key: keyof ProductDocument,
  value: any
): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, { [key]: value });
}

export async function bumpProduct(id: string): Promise<void> {
  try {
    const ref = doc(productsRef, id);
  return await updateDoc(ref, { date_added: Date.now(), bumps: increment(1) });
  } catch (e) {
    console.log("[bumpProduct] Error", e)
  }
}

export async function updateStatus(id: string, status: any): Promise<void> {
  try {
    const ref = doc(productsRef, id);
    return await updateDoc(ref, { statuses: arrayUnion(status) });
  } catch (e) {
    console.log("[updateStatus] Error", e)
  }
}

export async function expireProduct(id: string, expired: boolean): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, { is_expired: !expired });
}

export async function renewProduct(id: string): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, { is_expired: false, date_updated: Date.now() });
}

export async function verifyProduct(id: string, verify: boolean): Promise<void> {
  const ref = doc(productsRef, id);
  return updateDoc(ref, { is_verified: !verify });
}

export async function removeProductFromFavorites(
  uid: string,
  productId: string
): Promise<void> {
  const ref = doc(usersRef, uid);
  return updateDoc(ref, { favorites: arrayRemove(productId) });
}

export async function addProductToFavorites(
  uid: string,
  productId: string
): Promise<void> {
  const ref = doc(usersRef, uid);
  return updateDoc(ref, { favorites: arrayUnion(productId) });
}

export async function getCuratedLists(): Promise<CuratedListDocument[]> {
  const snapshot = await getDocs(listsRef);
  return snapshot.docs.map((d) => d.data()) ?? [];
}

export async function addProductToCuratedList(
  productId: string,
  curated_list: string
): Promise<void> {
  const ref = doc(productsRef, productId);
  return updateDoc(ref, { curated_lists: arrayUnion(curated_list) });
}

export async function removeProductFromCuratedList(
  productId: string,
  curated_list: string
): Promise<void> {
  const ref = doc(productsRef, productId);
  return updateDoc(ref, { curated_lists: arrayRemove(curated_list) });
}

export async function setVacationMode(uid: string, on = false) {
  const { data } = await httpsCallable<
    { uid: string; on: boolean },
    { updated: number }
  >(
    functions,
    'vacationMode'
  )({ uid, on });
  return data?.updated ?? 0;
}

const getBrandsFromResult = (products: ProductDocument[]) =>
  [...new Set(products.map((p) => p.brand))].sort();

const brandsRef = collection(db, 'brands');

export const getBrandsByCategory = async (category: string) => {
  const q = query(
    brandsRef,
    where('categories', 'array-contains', category),
    orderBy('name', 'asc')
  );

  const { docs } = await getDocs(q);
  return docs.map((d) => d.data()).map((d) => d.name);
};

const getAvailabilityConstraint = (availabilityQueryValue?: Availability) => {
  if (availabilityQueryValue === 'inStock') {
    return [where('out_of_stock', '==', false)];
  }
  if (availabilityQueryValue === 'sold') {
    return [where('out_of_stock', '==', true), where('is_draft', '==', false)];
  }
  if (availabilityQueryValue === 'draft') {
    return [where('is_draft', '==', true)];
  }
  if (availabilityQueryValue === 'onSale') {
    return [where('on_sale', '==', true), where('out_of_stock', '==', false)];
  }
  return [];
};

export const MAX_META_DESCRIPTION_LENGTH = 160;
export const READ_MORE_TEXT = "...read more.";

export const formatListingMetaDescription = (description: string, descriptionHtml: string): string => {
  let metaDescription = description;

  // Check if the description starts with "Notes:"
  if (description.startsWith("Notes:")) {
    metaDescription = descriptionHtml || description; // Fallback to description if descriptionHtml is empty

    // Strip HTML tags if descriptionHtml is being used
    metaDescription = metaDescription.replace(/<[^>]*>/g, "").trim();
  }

  // Calculate maximum allowed characters for the main content
  const maxContentLength = MAX_META_DESCRIPTION_LENGTH - READ_MORE_TEXT.length;

  // Truncate to fit the meta description limit, leaving space for "...read more."
  if (metaDescription.length > maxContentLength) {
    metaDescription = metaDescription.slice(0, maxContentLength).trim();

    // Ensure no word is cut mid-way
    const lastSpace = metaDescription.lastIndexOf(" ");
    if (lastSpace > 0) {
      metaDescription = metaDescription.slice(0, lastSpace);
    }
  }

  // Append "...read more." to the final description
  metaDescription += READ_MORE_TEXT;

  return metaDescription;
};